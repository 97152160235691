import { Typography, SxProps } from '@mui/material';

export default function ComingSoon({ sx }: { sx?: SxProps }) {
  return (
    <Typography
      sx={{
        color: '#9B9FA3',
        borderRadius: '120px',
        px: 2,
        border: '1px solid #9B9FA3',
        fontSize: '12px',
        alignSelf: 'center',
        width: 'fit-content',
        ...sx,
      }}
    >
      Coming soon
    </Typography>
  );
}
